@import 'styles/mixins';
@import 'styles/colors';

.searchBg {
  height: auto;
  width: 100%;
  text-align: center;
  padding: 33px 100px 10px;
  align-items: center;
  justify-content: space-between;
  position: relative;
  z-index: 1;

  @include down('sm') {
    padding: 18px 16px;
    display: block;
    height: 230px;
  }

  .bg {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    box-sizing: border-box;
    padding: 0;
    border: none;
    margin: auto;
    display: block;
    width: 0;
    height: 0;
    min-width: 100%;
    max-width: 100%;
    min-height: 100%;
    max-height: 100%;
    object-fit: cover;
    object-position: center top;
    overflow: hidden;
    z-index: -1;
  }

  .mainContent {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    .mainHeading {
      margin-bottom: 33px;
      @include down('sm') {
        font-size: 20px;
      }
    }
    .subHeading {
      margin-bottom: 10px;
      @include down('sm') {
        font-size: 16px;
      }
    }

    .stats {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-wrap: wrap;

      .statItem {
        margin: 10px 22px;
        color: $white;
        font-weight: bold;
        margin-right: 3px;
        font-size: 14px;
        display: flex;
        align-items: center;

        @include down('sm') {
          flex-direction: column;
          margin: 10px;
        }
        @include down('xs') {
          margin: 5px;
        }

        .statInfo {
          font-weight: lighter;
          font-size: 12px;
          color: $white;
          margin-left: 0.5em;

          @include down('sm') {
            margin-left: 0;
          }
        }
      }
    }
  }

  .newContent {
    display: none;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-between;
    padding: 16px 12px;
    min-width: 283px;
    height: 153px;
    left: 1019px;
    top: 166px;
    background: #ffffff;
    border-radius: 8px;

    .header {
      font-weight: 500;
      font-size: 20px;
      line-height: 27px;
      &:first-letter {
        text-transform: uppercase;
      }
    }

    .content {
      font-weight: 500;
      font-size: 14px;
      line-height: 18px;
      color: #757171;
      text-align: left;
    }

    .button {
      display: flex;
      align-items: center;
      justify-content: center;
      border: none;
      width: 132px;
      min-height: 38px;
      background: #e3763e;
      border-radius: 8px;
      color: white;
      cursor: pointer;
      font-weight: 500;
      font-size: 12px;
      line-height: 14px;
      .icon {
        width: 12px;
        height: 12px;
        margin-left: 5px;
      }
    }
  }

  &:global(.vwo) {
    padding: 43px 24px 43px 37px;
    .mainContent {
      align-items: flex-start;
      .mainHeading {
        text-align: left;
        padding: 0;
        padding-right: 30px;
      }
    }

    .newContent {
      display: flex;
    }
  }
}

.pro {
  font-weight: bold;
  color: #d96125;
}
